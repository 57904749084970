import { ApolloCartErrorAlert, useCartQuery } from '@graphcommerce/magento-cart'
import { CartPageDocument } from '@graphcommerce/magento-cart-checkout'
import { Money } from '@graphcommerce/magento-store'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Paper } from '@mui/material'
import Link from 'next/link'
import { RelatedProductGroups } from '../Amasty/RelatedProductGroups'
import { TertiaryButton } from '../Button/ButtonStyles'
import { CheckoutButton } from '../Cart/CheckoutButton/CheckoutButton'
import { CartItem } from '../CartItems/CartItem/CartItem'
import { CartItems } from '../CartItems/CartItems/CartItems'
import { EmptyCart } from '../EmptyCart/EmptyCart'
import { SlideinModalHeader } from '../Layout/SlideinModalHeader'
import { ConfigurableMinicartItem } from '../ProductConfigurable/ConfigurableCartItem/ConfigurableMinicartItem'
import { SimpleMinicartItem } from '../ProductSimple/SimpleCartItem/SimpleMinicartItem'
import { CartItemSkeleton } from '../Skeleton/CartItemSkeleton'

type MinicartProps = {
  onClose: () => unknown
}

function Minicart(props: MinicartProps) {
  const { onClose } = props
  const cart = useCartQuery(CartPageDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  })
  const { data, error, loading } = cart
  const hasItems =
    (data?.cart?.total_quantity ?? 0) > 0 &&
    typeof data?.cart?.prices?.grand_total?.value !== 'undefined'

  const amountOfCartItems =
    data?.cart?.items && data?.cart?.items?.length > 0 ? data?.cart?.items?.length : '0'
  return (
    <>
      <div className=' md:pb-15 max-w-[100vw] bg-pure-white p-6 md:max-w-none md:pt-0'>
        <SlideinModalHeader
          title='Shopping Cart'
          subText={`(${amountOfCartItems})`}
          // icon='shopping_bag'
          onClose={onClose}
        />
        {loading && (
          <div className='mx-auto flex min-w-[300px] flex-col gap-y-5 md:min-w-[500px]'>
            <CartItemSkeleton isMiniCartItem />
            <CartItemSkeleton isMiniCartItem />
          </div>
        )}
        {!loading && hasItems && (
          <div>
            <div className='mb:border-b-11 last:nor'>
              <CartItems
                containerClass=''
                items={data?.cart?.items}
                id={data?.cart?.id ?? ''}
                key='cart'
                renderer={{
                  BundleCartItem: CartItem,
                  ConfigurableCartItem: ConfigurableMinicartItem,
                  DownloadableCartItem: CartItem,
                  SimpleCartItem: SimpleMinicartItem,
                  VirtualCartItem: CartItem,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore GiftCardProduct is only available in Commerce
                  GiftCardCartItem: CartItem,
                }}
              />
            </div>
            <ApolloCartErrorAlert error={error} />
          </div>
        )}
        {!loading && !hasItems && <EmptyCart handleCloseMiniCart={onClose} />}
      </div>
      <div className='flex flex-col bg-pure-white'>
        <Paper
          className='bg-pure-white] flex flex-col border-t-[1px] border-20-grey px-11 py-7'
          elevation={6}
        >
          <div className='flex w-full flex-col items-center justify-center gap-4'>
            {!!data?.cart?.prices?.setup_fees_total?.value && (
              <div className='mx-10 flex w-full  max-w-[70%] items-center justify-between'>
                <span className='Type-XL-Regular text-dark-gray'>
                  <Trans id='Setup cost' />
                </span>
                <span className='Type-XL-Regular'>
                  <Money
                    value={data?.cart?.prices?.setup_fees_total?.value ?? 0}
                    currency={data?.cart?.prices?.subtotal_including_tax?.currency}
                  />
                </span>
              </div>
            )}
            <div className='mx-10 flex w-full  max-w-[70%] items-center justify-between'>
              <span className='Type-XXL-Regular text-dark-gray'>
                <Trans id='Cart total' />
              </span>
              <span className='Type-XXL-Regular'>
                <Money {...data?.cart?.prices?.subtotal_excluding_tax_and_setup} />
              </span>
            </div>
          </div>
          <div className='mx-5 mt-[20px] grid gap-[14px]'>
            <CheckoutButton />
            <Link href='/cart'>
              <TertiaryButton
                type='button'
                className='w-full bg-[#F2F2F2] uppercase'
                color='secondary'
                variant='contained'
                size='small'
                onClick={() => onClose()}
              >
                {i18n._(/* i18n */ 'View Cart')}
              </TertiaryButton>
            </Link>
          </div>
        </Paper>
        {hasItems && data.cart?.items?.at(0)?.product.uid && (
          <div className='mt-[100px]'>
            <RelatedProductGroups
              position='cart_content_bottom'
              classes={{ list: 'grid grid-cols-2 gap-[40px]' }}
              displayOnMinicart
            />
          </div>
        )}
      </div>
    </>
  )
}

export { Minicart }
