import { useApolloClient } from '@graphcommerce/graphql'
import { ApolloCustomerErrorAlert } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorAlert'
import { SignInDocument } from '@graphcommerce/magento-customer/components/SignInForm/SignIn.gql'
import { CustomerDocument } from '@graphcommerce/magento-customer/hooks'
import { graphqlErrorByCategory } from '@graphcommerce/magento-graphql'
import { FormActions } from '@graphcommerce/next-ui'
import { emailPattern, useFormGqlMutation } from '@graphcommerce/react-hook-form'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Alert, AlertTitle, Box, FormControl, SxProps, Theme } from '@mui/material'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { TertiaryButton, StandardButton } from '../../Button/ButtonStyles'
import { globalContext } from '../../NextUi/globalContext'
import { StandardTextField } from '../../TextInput/TextFieldStyles'

type SignInFormProps = {
  email: string
  sx?: SxProps<Theme>
  switchModeToForgotPass: () => void
  hasSocials?: boolean
  setHaSocials?: Dispatch<SetStateAction<boolean>>
  disableFields?: boolean
  register?: any
  mode?: any
  switchModeToSignUp: () => void
}

export function SignInForm(props: SignInFormProps) {
  const {
    email,
    switchModeToForgotPass,
    sx,
    hasSocials,
    setHaSocials,
    disableFields,
    register: registerEmail,
    mode,
    switchModeToSignUp,
  } = props

  const { drawer, setRedirectAfterSignIn } = useContext(globalContext)
  const client = useApolloClient()
  const form = useFormGqlMutation(
    SignInDocument,
    {
      defaultValues: { email },
      onBeforeSubmit: async (values) => {
        const oldEmail = client.cache.readQuery({ query: CustomerDocument })?.customer?.email

        /**
         * We are logging in because the session expired, but we're logging in with a different
         * email address, we need to reset the store.
         */
        if (oldEmail && oldEmail !== email) await client.resetStore()
        return { ...values, email }
      },
    },
    { errorPolicy: 'all' },
  )

  const { register, handleSubmit, required, formState, error } = form
  const [remainingError, authError] = graphqlErrorByCategory({
    category: 'graphql-authentication',
    error,
  })
  const submitHandler = handleSubmit(() => {
    if (drawer.id === 'auth' && typeof drawer.params?.redirectAfterSignIn === 'string') {
      setRedirectAfterSignIn(drawer.params?.redirectAfterSignIn)
    }
  })

  const [authErrorMessage, setAuthErrorMessage] = useState<string>('')
  const [authErrorMessageTitle, setAuthErrorMessageTitle] = useState<string>('Sign in Error')

  useEffect(() => {
    const authErrorMessageLocal =
      authError && authError?.message?.indexOf('The account sign-in was incorrect') >= 0
        ? 'Invalid password, please try again'
        : authError?.message

    if ((authError?.extensions?.hasSocials as Array<string>)?.includes('google') && setHaSocials) {
      setHaSocials(true)
      setAuthErrorMessageTitle(i18n._('Google Sign In Was Previously Used'))
      setAuthErrorMessage(i18n._('Please use Google to sign in'))
    } else {
      setAuthErrorMessage(authErrorMessageLocal ?? '')
    }
  }, [authError, setHaSocials])

  return (
    <Box component='form' onSubmit={submitHandler} noValidate sx={sx}>
      {!hasSocials && (
        <div className='flex w-[260px] max-w-[260px] flex-col justify-center gap-[22px]'>
          <StandardTextField
            label={<Trans id='Email address' />}
            variant='outlined'
            type='email'
            autoComplete='email'
            color='primary'
            size='small'
            error={formState.isSubmitted && !!formState.errors.email}
            disabled={disableFields || formState.isSubmitting}
            {...registerEmail('email', {
              required: required.email,
              pattern: { value: emailPattern, message: '' },
            })}
            required={required.email}
            className='outline-material-ui-blue'
          />
          {mode !== 'signedin' && mode !== 'forgot' && mode !== 'signup' && !hasSocials && (
            <div className='w-full'>
              <StandardTextField
                id='current-password'
                key='password'
                type='password'
                label={<Trans id='password' />}
                variant='outlined'
                autoComplete='current-password'
                error={!!formState.errors.password || !!authError}
                color='primary'
                disabled={disableFields || formState.isSubmitting}
                required={required.password}
                {...register('password', { required: required.password })}
                className='w-[260px] max-w-[260px] outline-none outline-0'
                size='small'
              />
              <div className='mt-[5px] text-right'>
                <button
                  className='Type-Large-Regular text-material-ui-blue'
                  onClick={() => switchModeToForgotPass()}
                  type='button'
                >
                  <Trans id='Forgot password?' />
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {authError && (
        <Alert severity='error' className='mt-5 max-w-[260px]'>
          <AlertTitle className='Type-Large-Medium'>{authErrorMessageTitle}</AlertTitle>
          {authErrorMessage}
        </Alert>
      )}
      <ApolloCustomerErrorAlert error={remainingError} key='error' />
      {!hasSocials && mode !== 'signup' && (
        <FormActions className='w-full'>
          <FormControl className='flex w-full flex-col gap-10'>
            <StandardButton
              type='submit'
              className='w-[260px] max-w-[260px] bg-material-ui-blue uppercase'
              variant='contained'
              loading={formState.isSubmitting}
            >
              {i18n._('Sign in')}
            </StandardButton>
            <TertiaryButton
              type='button'
              className='w-[260px] max-w-[260px] rounded-[100px] border-[1px] border-solid border-50-grey bg-pure-white uppercase hover:bg-[#F2F2F2]'
              variant='contained'
              onClick={() => switchModeToSignUp()}
            >
              {i18n._('Create Account')}
            </TertiaryButton>
          </FormControl>
        </FormActions>
      )}
    </Box>
  )
}
